* {
    padding: 0;
    margin: 0
}


button {
    padding: 20px 30px;
    font-size: 1.5em;
    /*width:200px;*/
    cursor: pointer;
    border: 0px;
    position: relative;
    /*margin: 20px;*/
    transition: all .25s ease;
    /*background: rgba(116, 23, 231, 1);*/
    color: #fff;
    overflow: hidden;
    border-radius: 10px
}

.load {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: inherit
}

.load::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    border: 3px solid #fff;
    width: 30px;
    height: 30px;
    border-left: 3px solid transparent;
    border-bottom: 3px solid transparent;
    animation: loading1 1s ease infinite;
    z-index: 10
}

.load::before {
    content: '';
    position: absolute;
    border-radius: 50%;
    border: 3px dashed #fff;
    width: 30px;
    height: 30px;
    border-left: 3px solid transparent;
    border-bottom: 3px solid transparent;
    animation: loading1 2s linear infinite;
    z-index: 5
}

@keyframes loading1 {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

button.active {
    transform: scale(.85)
}

button.activeLoading .loading {
    visibility: visible;
    opacity: 1
}

button .loading {
    opacity: 0;
    visibility: hidden
}


.loader-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /*position: fixed;*/
    /*background: rgba(0, 0, 0, 0.834);*/
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #3d5af1 transparent #3d5af1 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}



.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background-color: rgb(138, 138, 138);*/
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loader {
    border: 10px solid #f3f3f3;
    border-top: 10px solid #3498db;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
}

@keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
}

/* The Close Button */
.close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.modal-header {
    padding: 2px 16px;
    background-color: #188dd5;
    color: white;
}

.modal-body {padding: 10px 16px;}

.modal-footer {
    padding: 2px 16px;
    background-color: #188dd5;
    color: white;
}
@media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
        animation: none;
    }
}

.list-group {
    --bs-list-group-color: inherit;
    --bs-list-group-bg: #fff;
    --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
    --bs-list-group-border-width: 1px;
    --bs-list-group-border-radius: 0.375rem;
    --bs-list-group-item-padding-x: 1rem;
    --bs-list-group-item-padding-y: 0.5rem;
    --bs-list-group-action-color: #495057;
    --bs-list-group-action-hover-color: #495057;
    --bs-list-group-action-hover-bg: #f8f9fa;
    --bs-list-group-action-active-color: #7b809a;
    --bs-list-group-action-active-bg: #f0f2f5;
    --bs-list-group-disabled-color: #6c757d;
    --bs-list-group-disabled-bg: #fff;
    --bs-list-group-active-color: #fff;
    --bs-list-group-active-bg: #e91e63;
    --bs-list-group-active-border-color: #e91e63;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
    list-style-type: none;
    counter-reset: section;
}

.list-group-numbered>.list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section;
}

.list-group-item-action {
    width: 100%;
    color: var(--bs-list-group-action-color);
    text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg);
}

.list-group-item-action:active {
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
    position: relative;
    display: block;
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color);
}

.list-group-item+.list-group-item {
    border-top-width: 0;
}

.list-group-item+.list-group-item.active {
    margin-top: calc(-1 * var(--bs-list-group-border-width));
    border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
    flex-direction: row;
}

.list-group-horizontal>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
}

.list-group-horizontal>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
}

.list-group-horizontal>.list-group-item.active {
    margin-top: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
    .list-group-horizontal-sm {
        flex-direction: row;
    }

    .list-group-horizontal-sm>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0;
    }

    .list-group-horizontal-sm>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-sm>.list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0;
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width);
    }
}

@media (min-width: 768px) {
    .list-group-horizontal-md {
        flex-direction: row;
    }

    .list-group-horizontal-md>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0;
    }

    .list-group-horizontal-md>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-md>.list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0;
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width);
    }
}

@media (min-width: 992px) {
    .list-group-horizontal-lg {
        flex-direction: row;
    }

    .list-group-horizontal-lg>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0;
    }

    .list-group-horizontal-lg>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-lg>.list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0;
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width);
    }
}

@media (min-width: 1200px) {
    .list-group-horizontal-xl {
        flex-direction: row;
    }

    .list-group-horizontal-xl>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0;
    }

    .list-group-horizontal-xl>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-xl>.list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0;
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width);
    }
}

@media (min-width: 1400px) {
    .list-group-horizontal-xxl {
        flex-direction: row;
    }

    .list-group-horizontal-xxl>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0;
    }

    .list-group-horizontal-xxl>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-xxl>.list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0;
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width);
    }
}

.list-group-flush {
    border-radius: 0;
}

.list-group-flush>.list-group-item {
    border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0;
}

.list-group-item-primary {
    color: #8cb;
    background-color: #fbd2e0;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
    color: #8cb;
    background-color: #e2bdca;
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #8cb;
    border-color: #8cb;
}

.list-group-item-secondary {
    color: #4a4d5c;
    background-color: #e5e6eb;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
    color: #4a4d5c;
    background-color: #cecfd4;
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #4a4d5c;
    border-color: #4a4d5c;
}

.list-group-item-success {
    color: #2e6930;
    background-color: #dbefdc;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
    color: #2e6930;
    background-color: #c5d7c6;
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #2e6930;
    border-color: #2e6930;
}

.list-group-item-info {
    color: #10458b;
    background-color: #d1e3fa;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
    color: #10458b;
    background-color: #bccce1;
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #10458b;
    border-color: #10458b;
}

.list-group-item-warning {
    color: #975400;
    background-color: #fee8cc;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
    color: #975400;
    background-color: #e5d1b8;
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #975400;
    border-color: #975400;
}

.list-group-item-danger {
    color: #922820;
    background-color: #fdd9d7;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
    color: #922820;
    background-color: #e4c3c2;
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #922820;
    border-color: #922820;
}

.list-group-item-light {
    color: #606162;
    background-color: #fcfcfd;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
    color: #606162;
    background-color: #e3e3e4;
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #606162;
    border-color: #606162;
}

.list-group-item-dark {
    color: #1f2b3e;
    background-color: #d6dae1;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
    color: #1f2b3e;
    background-color: #c1c4cb;
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1f2b3e;
    border-color: #1f2b3e;
}

.list-group-item-white {
    color: #666666;
    background-color: white;
}

.list-group-item-white.list-group-item-action:hover,
.list-group-item-white.list-group-item-action:focus {
    color: #666666;
    background-color: #e6e6e6;
}

.list-group-item-white.list-group-item-action.active {
    color: #fff;
    background-color: #666666;
    border-color: #666666;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #89879f;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.375rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
@media (prefers-reduced-motion: reduce) {
    .btn {
        transition: none; } }
.btn:hover {
    color: #89879f;
    text-decoration: none; }
.btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(58, 122, 254, 0.25); }
.btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none; }

.btn-primary {
    color: #fff;
    background-color: #1bbd36;
    border-color: #1bbd36; }
.btn-primary:hover {
    color: #fff;
    background-color: #1460fe;
    border-color: #0758fe; }
.btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 142, 254, 0.5); }
.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #1bbd36;
    border-color: #1bbd36; }
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0758fe;
    border-color: #0151f7; }
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 142, 254, 0.5); }

.btn-secondary {
    color: #fff;
    background-color: #24365c;
    border-color: #24365c; }
.btn-secondary:hover {
    color: #fff;
    background-color: #192641;
    border-color: #162037; }
.btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 84, 116, 0.5); }
.btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #24365c;
    border-color: #24365c; }
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #162037;
    border-color: #121b2e; }
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 84, 116, 0.5); }

.btn-success {
    color: #fff;
    background-color: #10ca93;
    border-color: #10ca93; }
.btn-success:hover {
    color: #fff;
    background-color: #0da779;
    border-color: #0c9b71; }
.btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 210, 163, 0.5); }
.btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #10ca93;
    border-color: #10ca93; }
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #0c9b71;
    border-color: #0b8f68; }
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 210, 163, 0.5); }

.btn-info {
    color: #fff;
    background-color: #00afef;
    border-color: #00afef; }
.btn-info:hover {
    color: #fff;
    background-color: #0093c9;
    border-color: #008abc; }
.btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 187, 241, 0.5); }
.btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #00afef;
    border-color: #00afef; }
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008abc;
    border-color: #0080af; }
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 187, 241, 0.5); }

.btn-warning {
    color: #212529;
    background-color: #ff9f00;
    border-color: #ff9f00; }
.btn-warning:hover {
    color: #fff;
    background-color: #d98700;
    border-color: #cc7f00; }
.btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 141, 6, 0.5); }
.btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ff9f00;
    border-color: #ff9f00; }
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7f00;
    border-color: #bf7700; }
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 141, 6, 0.5); }

.btn-danger {
    color: #fff;
    background-color: #f25767;
    border-color: #f25767; }
.btn-danger:hover {
    color: #fff;
    background-color: #ef3347;
    border-color: #ee283c; }
.btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 112, 126, 0.5); }
.btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #f25767;
    border-color: #f25767; }
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ee283c;
    border-color: #ed1c31; }
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 112, 126, 0.5); }

.btn-light {
    color: #212529;
    background-color: #ebeef6;
    border-color: #ebeef6; }
.btn-light:hover {
    color: #212529;
    background-color: #d1d8ea;
    border-color: #c8d0e6; }
.btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(205, 208, 215, 0.5); }
.btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #ebeef6;
    border-color: #ebeef6; }
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c8d0e6;
    border-color: #bfc9e2; }
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(205, 208, 215, 0.5); }

.btn-dark {
    color: #fff;
    background-color: #181c32;
    border-color: #181c32; }
.btn-dark:hover {
    color: #fff;
    background-color: #0c0e18;
    border-color: #070910; }
.btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 62, 81, 0.5); }
.btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #181c32;
    border-color: #181c32; }
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #070910;
    border-color: #030407; }
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 62, 81, 0.5); }

.btn-outline-primary {
    color: #1bbd36;
    border-color: #1bbd36; }
.btn-outline-primary:hover {
    color: #fff;
    background-color: #1bbd36;
    border-color: #1bbd36; }
.btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 122, 254, 0.5); }
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #1bbd36;
    background-color: transparent; }
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #1bbd36;
    border-color: #1bbd36; }
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 122, 254, 0.5); }

.btn-outline-secondary {
    color: #24365c;
    border-color: #24365c; }
.btn-outline-secondary:hover {
    color: #fff;
    background-color: #24365c;
    border-color: #24365c; }
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(36, 54, 92, 0.5); }
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #24365c;
    background-color: transparent; }
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #24365c;
    border-color: #24365c; }
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(36, 54, 92, 0.5); }

.btn-outline-success {
    color: #10ca93;
    border-color: #10ca93; }
.btn-outline-success:hover {
    color: #fff;
    background-color: #10ca93;
    border-color: #10ca93; }
.btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(16, 202, 147, 0.5); }
.btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #10ca93;
    background-color: transparent; }
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #10ca93;
    border-color: #10ca93; }
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(16, 202, 147, 0.5); }

.btn-outline-info {
    color: #00afef;
    border-color: #00afef; }
.btn-outline-info:hover {
    color: #fff;
    background-color: #00afef;
    border-color: #00afef; }
.btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 175, 239, 0.5); }
.btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #00afef;
    background-color: transparent; }
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00afef;
    border-color: #00afef; }
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 175, 239, 0.5); }

.btn-outline-warning {
    color: #ff9f00;
    border-color: #ff9f00; }
.btn-outline-warning:hover {
    color: #212529;
    background-color: #ff9f00;
    border-color: #ff9f00; }
.btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 159, 0, 0.5); }
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ff9f00;
    background-color: transparent; }
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9f00;
    border-color: #ff9f00; }
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 159, 0, 0.5); }

.btn-outline-danger {
    color: #f25767;
    border-color: #f25767; }
.btn-outline-danger:hover {
    color: #fff;
    background-color: #f25767;
    border-color: #f25767; }
.btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(242, 87, 103, 0.5); }
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #f25767;
    background-color: transparent; }
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f25767;
    border-color: #f25767; }
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(242, 87, 103, 0.5); }

.btn-outline-light {
    color: #ebeef6;
    border-color: #ebeef6; }
.btn-outline-light:hover {
    color: #212529;
    background-color: #ebeef6;
    border-color: #ebeef6; }
.btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(235, 238, 246, 0.5); }
.btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #ebeef6;
    background-color: transparent; }
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #ebeef6;
    border-color: #ebeef6; }
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(235, 238, 246, 0.5); }

.btn-outline-dark {
    color: #181c32;
    border-color: #181c32; }
.btn-outline-dark:hover {
    color: #fff;
    background-color: #181c32;
    border-color: #181c32; }
.btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(24, 28, 50, 0.5); }
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #181c32;
    background-color: transparent; }
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #181c32;
    border-color: #181c32; }
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(24, 28, 50, 0.5); }

.btn-link {
    font-weight: 400;
    color: #1bbd36;
    text-decoration: none; }
.btn-link:hover {
    color: #014dea;
    text-decoration: underline; }
.btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
.btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.765625rem;
    line-height: 1.5;
    border-radius: 0.2rem; }

.btn-block {
    display: block;
    width: 100%; }
.btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
    width: 100%; }

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.375rem; }
.card > hr {
    margin-right: 0;
    margin-left: 0; }
.card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem; }
.card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem; }

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem; }

.card-title {
    margin-bottom: 0.75rem; }

.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0; }

.card-text:last-child {
    margin-bottom: 0; }

.card-link:hover {
    text-decoration: none; }
.card-link + .card-link {
    margin-left: 1.25rem; }

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
.card-header:first-child {
    border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0; }
.card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125); }
.card-footer:last-child {
    border-radius: 0 0 calc(0.375rem - 1px) calc(0.375rem - 1px); }

.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0; }

.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem; }

.card-img {
    width: 100%;
    border-radius: calc(0.375rem - 1px); }

.card-img-top {
    width: 100%;
    border-top-left-radius: calc(0.375rem - 1px);
    border-top-right-radius: calc(0.375rem - 1px); }

.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(0.375rem - 1px);
    border-bottom-left-radius: calc(0.375rem - 1px); }

.card-deck {
    display: flex;
    flex-direction: column; }
.card-deck .card {
    margin-bottom: 15px; }
@media (min-width: 576px) {
    .card-deck {
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px; }
    .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
    display: flex;
    flex-direction: column; }
.card-group > .card {
    margin-bottom: 15px; }
@media (min-width: 576px) {
    .card-group {
        flex-flow: row wrap; }
    .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
    .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
    .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
    .card-group > .card:not(:last-child) .card-img-top,
    .card-group > .card:not(:last-child) .card-header {
        border-top-right-radius: 0; }
    .card-group > .card:not(:last-child) .card-img-bottom,
    .card-group > .card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0; }
    .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
    .card-group > .card:not(:first-child) .card-img-top,
    .card-group > .card:not(:first-child) .card-header {
        border-top-left-radius: 0; }
    .card-group > .card:not(:first-child) .card-img-bottom,
    .card-group > .card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0; } }

.card-columns .card {
    margin-bottom: 0.75rem; }
@media (min-width: 576px) {
    .card-columns {
        column-count: 3;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1; }
    .card-columns .card {
        display: inline-block;
        width: 100%; } }



.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d7dae3;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
@media (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none; } }
.form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #b9d0ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(58, 122, 254, 0.25); }
.form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff; }

.form-control-file,
.form-control-range {
    display: block;
    width: 100%; }

.col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5; }

.col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.09375rem;
    line-height: 1.5; }

.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.765625rem;
    line-height: 1.5; }

.form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    margin-bottom: 0;
    line-height: 1.5;
    color: #89879f;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0; }
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.765625rem;
    line-height: 1.5;
    border-radius: 0.2rem; }

.form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
    height: auto; }

.form-group {
    margin-bottom: 1rem; }
.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px; }
.form-row > .col,
.form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }


.text-white {
    color: #fff !important; }

.text-primary {
    color: #1bbd36 !important; }

a.text-primary:hover, a.text-primary:focus {
    color: #014dea !important; }

.text-secondary {
    color: #24365c !important; }

a.text-secondary:hover, a.text-secondary:focus {
    color: #0e1625 !important; }

.text-success {
    color: #10ca93 !important; }

a.text-success:hover, a.text-success:focus {
    color: #0a835f !important; }

.text-info {
    color: #00afef !important; }

a.text-info:hover, a.text-info:focus {
    color: #0077a3 !important; }

.text-warning {
    color: #ff9f00 !important; }

a.text-warning:hover, a.text-warning:focus {
    color: #b36f00 !important; }

.text-danger {
    color: #f25767 !important; }

a.text-danger:hover, a.text-danger:focus {
    color: #ea1228 !important; }

.text-light {
    color: #ebeef6 !important; }

a.text-light:hover, a.text-light:focus {
    color: #b6c1de !important; }

.text-dark {
    color: #181c32 !important; }

a.text-dark:hover, a.text-dark:focus {
    color: black !important; }

.text-body {
    color: #89879f !important; }

.text-muted {
    color: #89879f !important; }

.text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0; }

.text-decoration-none {
    text-decoration: none !important; }

.text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important; }

.text-reset {
    color: inherit !important; }

.visible {
    visibility: visible !important; }

.invisible {
    visibility: hidden !important; }

.modal-header {
    padding: 1rem 1.875rem; }
.modal-header .close {
    padding: 0.875rem 1.815rem;
    margin: 0;
    position: absolute;
    right: 10px;
    float: none;
    top: 0;
    font-size: 30px;
    font-weight: 100; }

.modal-body {
    padding: 1.875rem; }

.modal-footer {
    padding: 1rem 1.875rem; }

.modal-content {
    border-radius: 0.375rem; }

.modal-backdrop {
    z-index: 10 !important; }
.col-12 {
    flex: 0 0 100%;
    max-width: 100%; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; }

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }

.col-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

.col-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

.col-3 {
    flex: 0 0 25%;
    max-width: 25%; }

.col-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

.col-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

.col-6 {
    flex: 0 0 50%;
    max-width: 50%; }

.col-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

.col-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

.col-9 {
    flex: 0 0 75%;
    max-width: 75%; }

.col-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

.col-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

.col-12 {
    flex: 0 0 100%;
    max-width: 100%; }

.order-first {
    order: -1; }

.order-last {
    order: 13; }

.order-0 {
    order: 0; }

.order-1 {
    order: 1; }

.order-2 {
    order: 2; }

.order-3 {
    order: 3; }

.order-4 {
    order: 4; }

.order-5 {
    order: 5; }

.order-6 {
    order: 6; }

.order-7 {
    order: 7; }

.order-8 {
    order: 8; }

.order-9 {
    order: 9; }

.order-10 {
    order: 10; }

.order-11 {
    order: 11; }

.order-12 {
    order: 12; }

.offset-1 {
    margin-left: 8.3333333333%; }

.offset-2 {
    margin-left: 16.6666666667%; }

.offset-3 {
    margin-left: 25%; }

.offset-4 {
    margin-left: 33.3333333333%; }

.offset-5 {
    margin-left: 41.6666666667%; }

.offset-6 {
    margin-left: 50%; }

.offset-7 {
    margin-left: 58.3333333333%; }

.offset-8 {
    margin-left: 66.6666666667%; }

.offset-9 {
    margin-left: 75%; }

.offset-10 {
    margin-left: 83.3333333333%; }

.offset-11 {
    margin-left: 91.6666666667%; }

@media (min-width: 576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%; }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%; }

    .col-sm-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%; }

    .col-sm-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%; }

    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%; }

    .col-sm-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%; }

    .col-sm-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%; }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%; }

    .col-sm-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%; }

    .col-sm-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%; }

    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%; }

    .col-sm-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%; }

    .col-sm-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%; }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%; }

    .order-sm-first {
        order: -1; }

    .order-sm-last {
        order: 13; }

    .order-sm-0 {
        order: 0; }

    .order-sm-1 {
        order: 1; }

    .order-sm-2 {
        order: 2; }

    .order-sm-3 {
        order: 3; }

    .order-sm-4 {
        order: 4; }

    .order-sm-5 {
        order: 5; }

    .order-sm-6 {
        order: 6; }

    .order-sm-7 {
        order: 7; }

    .order-sm-8 {
        order: 8; }

    .order-sm-9 {
        order: 9; }

    .order-sm-10 {
        order: 10; }

    .order-sm-11 {
        order: 11; }

    .order-sm-12 {
        order: 12; }

    .offset-sm-0 {
        margin-left: 0; }

    .offset-sm-1 {
        margin-left: 8.3333333333%; }

    .offset-sm-2 {
        margin-left: 16.6666666667%; }

    .offset-sm-3 {
        margin-left: 25%; }

    .offset-sm-4 {
        margin-left: 33.3333333333%; }

    .offset-sm-5 {
        margin-left: 41.6666666667%; }

    .offset-sm-6 {
        margin-left: 50%; }

    .offset-sm-7 {
        margin-left: 58.3333333333%; }

    .offset-sm-8 {
        margin-left: 66.6666666667%; }

    .offset-sm-9 {
        margin-left: 75%; }

    .offset-sm-10 {
        margin-left: 83.3333333333%; }

    .offset-sm-11 {
        margin-left: 91.6666666667%; } }
@media (min-width: 768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%; }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%; }

    .col-md-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%; }

    .col-md-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%; }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%; }

    .col-md-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%; }

    .col-md-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%; }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%; }

    .col-md-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%; }

    .col-md-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%; }

    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%; }

    .col-md-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%; }

    .col-md-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%; }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%; }

    .order-md-first {
        order: -1; }

    .order-md-last {
        order: 13; }

    .order-md-0 {
        order: 0; }

    .order-md-1 {
        order: 1; }

    .order-md-2 {
        order: 2; }

    .order-md-3 {
        order: 3; }

    .order-md-4 {
        order: 4; }

    .order-md-5 {
        order: 5; }

    .order-md-6 {
        order: 6; }

    .order-md-7 {
        order: 7; }

    .order-md-8 {
        order: 8; }

    .order-md-9 {
        order: 9; }

    .order-md-10 {
        order: 10; }

    .order-md-11 {
        order: 11; }

    .order-md-12 {
        order: 12; }

    .offset-md-0 {
        margin-left: 0; }

    .offset-md-1 {
        margin-left: 8.3333333333%; }

    .offset-md-2 {
        margin-left: 16.6666666667%; }

    .offset-md-3 {
        margin-left: 25%; }

    .offset-md-4 {
        margin-left: 33.3333333333%; }

    .offset-md-5 {
        margin-left: 41.6666666667%; }

    .offset-md-6 {
        margin-left: 50%; }

    .offset-md-7 {
        margin-left: 58.3333333333%; }

    .offset-md-8 {
        margin-left: 66.6666666667%; }

    .offset-md-9 {
        margin-left: 75%; }

    .offset-md-10 {
        margin-left: 83.3333333333%; }

    .offset-md-11 {
        margin-left: 91.6666666667%; } }
@media (min-width: 992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%; }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%; }

    .col-lg-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%; }

    .col-lg-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%; }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%; }

    .col-lg-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%; }

    .col-lg-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%; }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%; }

    .col-lg-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%; }

    .col-lg-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%; }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%; }

    .col-lg-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%; }

    .col-lg-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%; }

    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%; }

    .order-lg-first {
        order: -1; }

    .order-lg-last {
        order: 13; }

    .order-lg-0 {
        order: 0; }

    .order-lg-1 {
        order: 1; }

    .order-lg-2 {
        order: 2; }

    .order-lg-3 {
        order: 3; }

    .order-lg-4 {
        order: 4; }

    .order-lg-5 {
        order: 5; }

    .order-lg-6 {
        order: 6; }

    .order-lg-7 {
        order: 7; }

    .order-lg-8 {
        order: 8; }

    .order-lg-9 {
        order: 9; }

    .order-lg-10 {
        order: 10; }

    .order-lg-11 {
        order: 11; }

    .order-lg-12 {
        order: 12; }

    .offset-lg-0 {
        margin-left: 0; }

    .offset-lg-1 {
        margin-left: 8.3333333333%; }

    .offset-lg-2 {
        margin-left: 16.6666666667%; }

    .offset-lg-3 {
        margin-left: 25%; }

    .offset-lg-4 {
        margin-left: 33.3333333333%; }

    .offset-lg-5 {
        margin-left: 41.6666666667%; }

    .offset-lg-6 {
        margin-left: 50%; }

    .offset-lg-7 {
        margin-left: 58.3333333333%; }

    .offset-lg-8 {
        margin-left: 66.6666666667%; }

    .offset-lg-9 {
        margin-left: 75%; }

    .offset-lg-10 {
        margin-left: 83.3333333333%; }

    .offset-lg-11 {
        margin-left: 91.6666666667%; } }
.flex-nowrap {
    flex-wrap: nowrap !important; }

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important; }

.flex-fill {
    flex: 1 1 auto !important; }

.flex-grow-0 {
    flex-grow: 0 !important; }

.flex-grow-1 {
    flex-grow: 1 !important; }

.flex-shrink-0 {
    flex-shrink: 0 !important; }

.flex-shrink-1 {
    flex-shrink: 1 !important; }

.justify-content-start {
    justify-content: flex-start !important; }

.justify-content-end {
    justify-content: flex-end !important; }

.justify-content-center {
    justify-content: center !important; }

.justify-content-between {
    justify-content: space-between !important; }

.justify-content-around {
    justify-content: space-around !important; }

.align-items-start {
    align-items: flex-start !important; }

.align-items-end {
    align-items: flex-end !important; }

.align-items-center {
    align-items: center !important; }

.align-items-baseline {
    align-items: baseline !important; }

.align-items-stretch {
    align-items: stretch !important; }

.align-content-start {
    align-content: flex-start !important; }

.align-content-end {
    align-content: flex-end !important; }

.align-content-center {
    align-content: center !important; }

.align-content-between {
    align-content: space-between !important; }

.align-content-around {
    align-content: space-around !important; }

.align-content-stretch {
    align-content: stretch !important; }

.align-self-auto {
    align-self: auto !important; }

.align-self-start {
    align-self: flex-start !important; }

.align-self-end {
    align-self: flex-end !important; }

.align-self-center {
    align-self: center !important; }

.align-self-baseline {
    align-self: baseline !important; }

.align-self-stretch {
    align-self: stretch !important; }

mark,
.mark {
    padding: 0.2em;
    background-color: #fcf8e3; }

.list-unstyled {
    padding-left: 0;
    list-style: none; }

.list-inline {
    padding-left: 0;
    list-style: none; }

.list-inline-item {
    display: inline-block; }
.list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
    font-size: 90%;
    text-transform: uppercase; }

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.09375rem; }

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d; }
.blockquote-footer::before {
    content: "\2014\00A0"; }
.img-fluid {
    max-width: 100%;
    height: auto; }

.img-thumbnail {
    padding: 0.25rem;
    background-color: #ebeef6;
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
    max-width: 100%;
    height: auto; }

.mr-3,
.mx-3 {
    margin-right: 1rem !important; }

.mb-3,
.my-3 {
    margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
    margin-left: 1rem !important; }

.m-4 {
    margin: 1.5rem !important; }

.mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important; }

.ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important; }

.m-lg-1 {
    margin: 0.25rem !important; }

.mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important; }

.mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important; }

.mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important; }
tabbar {
    float: left;
    width: 100%;
}

.tab-content {
    padding: 20px 0;
}

.tab-content p {
    margin: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: 300;
}

.tabbar .nav-tabs {
    border-bottom: solid #eee 1px;
}

.tabbar nav div.nav-tabs .nav-link {
    border-radius: 0;
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 300;
}


/** tabbar style 2 **/

.tab_style2 .tabbar nav div.nav-tabs .nav-link.active {
    background: #28a745;
    color: #fff;
    border-color: #28a745;
}


/** tabbar style 3 **/

.tab_style3 #v-pills-tab {
    float: left;
    width: auto;
    min-width: 120px;
}

.tab_style3 #v-pills-tabContent {
    float: left;
    width: 80%;
    padding: 0 20px;
}

.tab_style3 .tabbar {
    display: flex;
}

.tab_style3 div.tabbar div.nav-pills .nav-link.active {
    background: #28a745;
    color: #fff;
    border-color: #28a745;
}


.tab-content > .tab-pane {
    display: none; }
.tab-content > .active {
    display: block; }

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem; }
.navbar > .container,
.navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
    display: inline-block;
    padding-top: 0.3359375rem;
    padding-bottom: 0.3359375rem;
    margin-right: 1rem;
    font-size: 1.09375rem;
    line-height: inherit;
    white-space: nowrap; }
.navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
.navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center; }

.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.09375rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.375rem; }
.navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%; }

@media (max-width: 575.98px) {
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
        padding-right: 0;
        padding-left: 0; } }
@media (min-width: 576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
        flex-direction: row; }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
        flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
        display: none; } }
@media (max-width: 767.98px) {
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
        padding-right: 0;
        padding-left: 0; } }
@media (min-width: 768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
        flex-direction: row; }
    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
        flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
        display: none; } }
@media (max-width: 991.98px) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
        padding-right: 0;
        padding-left: 0; } }
@media (min-width: 992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
        flex-direction: row; }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
        flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
        display: none; } }
@media (max-width: 1199.98px) {
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
        padding-right: 0;
        padding-left: 0; } }
@media (min-width: 1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
        flex-direction: row; }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
        flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
        display: none; } }
@media (max-width: 1439.98) {
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid {
        padding-right: 0;
        padding-left: 0; } }
@media (min-width: 1440) {
    .navbar-expand-xxl {
        flex-flow: row nowrap;
        justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
        flex-direction: row; }
    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
    .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid {
        flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-collapse {
        display: flex !important;
        flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
        display: none; } }


.d-block {
    display: block !important; }

.d-table {
    display: table !important; }

.d-table-row {
    display: table-row !important; }

.d-table-cell {
    display: table-cell !important; }

.d-flex {
    display: flex !important; }

.d-inline-flex {
    display: inline-flex !important; }

@media (min-width: 576px) {
    .d-sm-none {
        display: none !important; }

    .d-sm-inline {
        display: inline !important; }

    .d-sm-inline-block {
        display: inline-block !important; }

    .d-sm-block {
        display: block !important; }

    .d-sm-table {
        display: table !important; }

    .d-sm-table-row {
        display: table-row !important; }

    .d-sm-table-cell {
        display: table-cell !important; }

    .d-sm-flex {
        display: flex !important; }

    .d-sm-inline-flex {
        display: inline-flex !important; } }
@media (min-width: 768px) {
    .d-md-none {
        display: none !important; }

    .d-md-inline {
        display: inline !important; }

    .d-md-inline-block {
        display: inline-block !important; }

    .d-md-block {
        display: block !important; }

    .d-md-table {
        display: table !important; }

    .d-md-table-row {
        display: table-row !important; }

    .d-md-table-cell {
        display: table-cell !important; }

    .d-md-flex {
        display: flex !important; }

    .d-md-inline-flex {
        display: inline-flex !important; } }
@media (min-width: 992px) {
    .d-lg-none {
        display: none !important; }

    .d-lg-inline {
        display: inline !important; }

    .d-lg-inline-block {
        display: inline-block !important; }

    .d-lg-block {
        display: block !important; }

    .d-lg-table {
        display: table !important; }

    .d-lg-table-row {
        display: table-row !important; }

    .d-lg-table-cell {
        display: table-cell !important; }

    .d-lg-flex {
        display: flex !important; }

    .d-lg-inline-flex {
        display: inline-flex !important; } }
@media (min-width: 1200px) {
    .d-xl-none {
        display: none !important; }

    .d-xl-inline {
        display: inline !important; }

    .d-xl-inline-block {
        display: inline-block !important; }

    .d-xl-block {
        display: block !important; }

    .d-xl-table {
        display: table !important; }

    .d-xl-table-row {
        display: table-row !important; }

    .d-xl-table-cell {
        display: table-cell !important; }

    .d-xl-flex {
        display: flex !important; }

    .d-xl-inline-flex {
        display: inline-flex !important; } }
@media (min-width: 1440) {
    .d-xxl-none {
        display: none !important; }

    .d-xxl-inline {
        display: inline !important; }

    .d-xxl-inline-block {
        display: inline-block !important; }

    .d-xxl-block {
        display: block !important; }

    .d-xxl-table {
        display: table !important; }

    .d-xxl-table-row {
        display: table-row !important; }

    .d-xxl-table-cell {
        display: table-cell !important; }

    .d-xxl-flex {
        display: flex !important; }

    .d-xxl-inline-flex {
        display: inline-flex !important; } }
@media print {
    .d-print-none {
        display: none !important; }

    .d-print-inline {
        display: inline !important; }

    .d-print-inline-block {
        display: inline-block !important; }

    .d-print-block {
        display: block !important; }

    .d-print-table {
        display: table !important; }

    .d-print-table-row {
        display: table-row !important; }

    .d-print-table-cell {
        display: table-cell !important; }

    .d-print-flex {
        display: flex !important; }

    .d-print-inline-flex {
        display: inline-flex !important; } }


.p-3 {
    padding: 1rem !important; }

.pt-3,
.py-3 {
    padding-top: 1rem !important; }

.pr-3,
.px-3 {
    padding-right: 1rem !important; }

.pb-3,
.py-3 {
    padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
    padding-left: 1rem !important; }

.p-4 {
    padding: 1.5rem !important; }

.pt-4,
.py-4 {
    padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
    padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
    padding-left: 1.5rem !important; }

.p-5 {
    padding: 3rem !important; }

.pt-5,
.py-5 {
    padding-top: 3rem !important; }

.pr-5,
.px-5 {
    padding-right: 3rem !important; }

.pb-5,
.py-5 {
    padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
    padding-left: 3rem !important; }

.m-n1 {
    margin: -0.25rem !important; }

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/* Common styles for button-pagination */
.button-pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.button-pagination button {
    margin: 0 5px;
    padding: 8px 16px;
    border: 2px solid #007bff;
    border-radius: 5px;
    background-color: #fff;
    color: #007bff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.button-pagination button:hover {
    background-color: #007bff;
    color: #fff;
}

.button-pagination button.active {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
    cursor: default;
}

.button-pagination button.disabled {
    pointer-events: none;
    opacity: 0.5;
}

/* Responsive styles for button-pagination */
@media (max-width: 600px) {
    .button-pagination {
        flex-direction: row;
        align-items: center;
    }

    .button-pagination button {
        margin: 5px;
    }
}

/* Basic styles for the modal */
.modal {
    display: block; /* Change to 'none' to initially hide the modal */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modal-content {
    background-color: #fcfcfc;
    width: 60%;
    max-width: 400px;
    margin: 100px auto;
    padding: 20px;
    border-radius: 5px;
}
.linear-loader {
    width: 100%;
    height: 4px;
    background-color: #ccc;
    position: relative;
    margin-top: 20px;
}

.bar {
    width: 0;
    height: 100%;
    background-color: #007bff;
    position: absolute;
    top: 0;
    left: 0;
    transition: width 0.2s ease-in-out;
}


